/**
 * 错误信息集
 */
const EDMDownloadErrorCodes = {
  DOWNLOAD_ERROR: {
    status: 500,
    message: "An exception occurred when initializing the document!",
  },
  LOAD_TOKEN_ERROR: {
    status: 500,
    message:
      "访问超时，请重新登录或联系IT人员;Access timed out. Please log in again or contact IT personnel.",
  },
  EMPTY_OPTIONS: {
    status: 90001,
    message: "实例参数为空,EDM download plugin options is empty!",
  },
  EMPTY_APP_ID: { status: 90002, message: "The appId is not set!" },
  EXCEEDS_LIMIT: {
    status: 90004,
    message: "The number of documents exceeds the limit!",
  },
  DOC_EMPTY: { status: 90006, message: "The document is empty!" },
  TOKEN_INVALID: { status: 90008, message: "The token is invalid!" },
  NO_PERMISSION: { status: 90009, message: "No operation permission!" },
  KIA_SCANNING: {
    status: 12079,
    message:
      "Sorry , unable to download. please pass the KIA checking" +
      "first to download a file using public network." +
      "Current document is being checked by the KIA, " +
      "please wait a while then redownload!",
  },
  DOES_NOT_EXIST: {
    status: 403,
    message: "The document cannot be found. It may not exist.",
  },
  NET_EXCEPTION: {
    status: 404,
    message: "The network is exception. Check the network environment.",
  },
  FILENAME_ERROR: {
    status: 12081,
    message: "The file name cannot start with a dot.",
  },
  CACHE_FULL: {
    status: 12082,
    message:
      "文件下载失败，浏览器缓存空间已满。可尝试关闭页面并清理磁盘空间后，重新打开页面下载。建议将浏览器下载目录设为存储空间较大的硬盘分区。Failed to download the file because the browser cache space is full. You can close the page, clear the disk space, and then open the page again. You are advised to set the browser download directory to a hard disk partition with large storage space.",
  },
  NOT_DOWNLOAD_PERMISSION: {
    status: 12083,
    message: "You do not have the download permission or the source document information has been destroyed.",
  },
  REQUEST_FAILED: {
    status: 12084,
    message:
      "Failed to send the request. Refresh the page and download the file again.",
  },
  CLIENTPACK_SIZE_LIMIT: {
    status: 12085,
    message: "文件总大小超过批量下载上限.The total file size exceeds the upper limit for batch download."
  },
  PACKAGE_DOWNLOAD_ERROR: {
    status: 12082,
    message: "Package download error: Some documents have been deleted or do not exist or are not allowed to be downloaded at the current site."
  },
  FILE_INCOMPLETE: {
    status: 12086, 
    message: "文件下载失败，分片合并完整性校验不通过。The file fails to be downloaded, and the integrity check for segment merging fails."
  },
  ZERO_SIZE_FILE: {
    status: 12096,
    message: "The file with size 0 is not allowed.",
  },
};

export default EDMDownloadErrorCodes
